import React, { Suspense, lazy, useState } from 'react';
import styled from 'styled-components';

import type { FieldType } from '@lt/widget/components/Cigarette/types';

import { SEARCH_TYPE_NAMES } from 'src/constants/search';

import ErrorBoundary from 'src/error/components/ErrorBoundary';
import CigaretteSkeleton from 'src/components/CigaretteSkeleton';

import DesktopCigarettePreview from '../DesktopCigarettePreview';
import WizardCigarettePreview from '../WizardCigarettePreview';

import type { ILazyCigarette } from './types';
import { CIGARETTE_FILTER_TEST_ID } from './constants';

const Cigarette = lazy(
  () => import(/* webpackChunkName: "Cigarette" */ 'src/components/Cigarette'),
);

export const LazyCigarette = (props: ILazyCigarette) => {
  const {
    isMobile = false,
    searchType,
    cigaretteValues,
    isMainPage = false,
    className = '',
    setIsWizardOpen,
    isWizardOpen = false,
    trackSubmit,
    variant = 'default',
    onSubmit,
    allowPreview = true,
    inHeader = false,
    initialOpenedField,
  } = props;

  const [activeField, setActiveField] = useState<FieldType | undefined>(
    initialOpenedField,
  );

  const showPreview = allowPreview && !activeField;

  const PreviewComponent = isMobile
    ? WizardCigarettePreview
    : DesktopCigarettePreview;

  const mobileVariant =
    (isMobile && isMainPage && 'wizard') ||
    (isMobile && 'default') ||
    undefined;

  const preparedCigaretteValues =
    searchType === SEARCH_TYPE_NAMES.TRIP ? undefined : cigaretteValues;

  const loadCigarette = (clickedPreviewField: FieldType) => {
    setActiveField(clickedPreviewField);
  };

  return showPreview ? (
    <PreviewComponent
      onFieldClick={loadCigarette}
      cigaretteValues={preparedCigaretteValues}
      searchType={searchType}
      variant={variant}
      data-testid={CIGARETTE_FILTER_TEST_ID}
    />
  ) : (
    <ErrorBoundary showDialog={false}>
      <Suspense
        fallback={
          <CigaretteSkeleton hasMaxWidth={!inHeader} hasButton={!isMobile} />
        }
      >
        <StyledCigarette
          className={className}
          isMainPage={isMainPage}
          variant={variant}
          mobileVariant={mobileVariant}
          searchType={searchType}
          trackSubmit={trackSubmit}
          setIsWizardOpen={setIsWizardOpen}
          $isWizardOpen={isWizardOpen}
          initialValues={preparedCigaretteValues}
          initialOpenedField={activeField}
          onSubmit={onSubmit}
          data-testid={CIGARETTE_FILTER_TEST_ID}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

const StyledCigarette = styled(Cigarette)<{ $isWizardOpen?: boolean }>`
  ${({ $isWizardOpen, mobileVariant }) =>
    $isWizardOpen &&
    mobileVariant &&
    `
      position: fixed;
      left: 0;
    `}
`;
