export const SEARCH_TAB_TOURS_DATA_TEST_ID = 'SEARCH_TAB_TOURS_DATA_TEST_ID';
export const SEARCH_TAB_HOTELS_DATA_TEST_ID = 'SEARCH_TAB_HOTELS_DATA_TEST_ID';
export const SEARCH_TAB_EXCURSIONS_DATA_TEST_ID =
  'SEARCH_TAB_EXCURSIONS_DATA_TEST_ID';

export const TABS_PAGES = {
  SEARCH: 'SEARCH',
  MAIN: 'MAIN',
  OLD_MAIN: 'OLD_MAIN',
  HOTEL: 'HOTEL',
} as const;

export const TABS_STYLES = {
  DESKTOP: {
    MAIN_PAGE: {
      ENABLED: {
        TAB: `
        color: white;
        background: #17181a;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: white;`,
      },
      DISABLED: {
        TAB: `
        color: white;
        background: transparent;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: white;`,
      },
    },
    SEARCH: {
      ENABLED: {
        TAB: `
        color: black;
        background: white;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: black;`,
      },
      DISABLED: {
        TAB: `
        color: white;
        background: transparent;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: white;`,
      },
    },
    HOTEL: {
      ENABLED: {
        TAB: `
        font-weight: 700;
        color: black;
        background: white;
      `,
        ICON: `stroke: black;`,
      },
      DISABLED: {
        TAB: `
        font-weight: 700;
        color: #007AFF;
        background: transparent;
      `,
        ICON: `stroke: #007AFF;`,
      },
    },
  },
  MOBILE: {
    MAIN_PAGE: {
      OLD: {
        ENABLED: {
          TAB: `
          color: black;
          background: white;
        `,
          ICON: `stroke: black;`,
        },
        DISABLED: {
          TAB: `
          color: #919499;
          background: transparent;
        `,
          ICON: `stroke: #919499;`,
        },
      },
      NEW: {
        ENABLED: {
          TAB: `
          color: black;
          background: white;
        `,
          ICON: `stroke: black;`,
        },
        DISABLED: {
          TAB: `
          color: white;
          background: transparent;
        `,
          ICON: `stroke: white;`,
        },
      },
    },
    SEARCH: {
      COLLAPSED: {
        ENABLED: {
          TAB: `
          color: white;
          background: linear-gradient(90deg, #494949 0%, #3c3c3d 100%);
        `,
          ICON: `stroke: white;`,
        },
        DISABLED: {
          TAB: `
          color: #919499;
          background: transparent;
        `,
          ICON: `stroke: #919499;`,
        },
      },
      EXPANDED: {
        ENABLED: {
          TAB: `
          color: white;
          background: #4d4d4d;
        `,
          ICON: `stroke: white`,
        },
        DISABLED: {
          TAB: `
          color: #c2c5cc;
          background: transparent;
        `,
          ICON: `stroke: #c2c5cc;`,
        },
      },
    },
    HOTEL: {
      ENABLED: {
        TAB: `
        color: black;
        background: white;
      `,
        ICON: `stroke: black;`,
      },
      DISABLED: {
        TAB: `
        color: #007AFF;
        background: transparent;
      `,
        ICON: `stroke: #007AFF;`,
      },
    },
  },
} as const;

export const WL_TABS_STYLES = {
  ...TABS_STYLES,
  DESKTOP: {
    MAIN_PAGE: {
      ENABLED: {
        TAB: `
        color: white;
        background: #17181a;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: white;`,
      },
      DISABLED: {
        TAB: `
        color: white;
        background: transparent;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: white;`,
      },
    },
    SEARCH: {
      ENABLED: {
        TAB: `
        color: black;
        background: white;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: black;`,
      },
      DISABLED: {
        TAB: `
        color: white;
        background: transparent;
        font-size: 14px;
        font-weight: 700;
      `,
        ICON: `stroke: white;`,
      },
    },
    HOTEL: {
      ENABLED: {
        TAB: `
        font-weight: 700;
        color: black;
        background: white;
      `,
        ICON: `stroke: black;`,
      },
      DISABLED: {
        TAB: `
        font-weight: 700;
        color: #007AFF;
        background: transparent;
      `,
        ICON: `stroke: #007AFF;`,
      },
    },
  },
} as const;
