import React, { lazy, Suspense, useState } from 'react';
import type { FC } from 'react';
import classnames from 'classnames';

import HeaderMenu, {
  HeaderMenuCategory,
} from '@lt/components/components/HeaderMenu';

import { isSSR } from 'src/helpers/ssr';
import HeaderFeedback from '../../HeaderFeedback';
import HeaderWishlist from '../../HeaderWishlist';

import type { IHeaderMenu } from '../types';
import styles from './desktopMenu.module.css';
import {
  HEADER_CONTACT_US_TEST_ID,
  HEADER_SERVICES_TEST_ID,
  HEADER_MY_LIST_TEST_ID,
  HEADER_HELP_TEST_ID,
  HEADER_LOGIN_TEST_ID,
} from '../constants';

const HeaderAuth = lazy(
  () =>
    import(
      /* webpackChunkName: "HeaderAuth" */ 'src/components/Header/components/HeaderAuth'
    ),
);

const DesktopMenu: FC<IHeaderMenu> = ({
  services,
  help,
  className,
  isCollapsed,
  showAuthorizationBlock = true,
  showWishlist = true,
  showFeedback,
  isHomepage,
}) => {
  /*
  Стейт showAuthFromWishlist нужен, чтобы при вызове авторизации из избранного  
  отображалась только одна форма авторизации
*/
  const [showAuthFromWishlist, setShowAuthFromWishlist] =
    useState<boolean>(false);
  return (
    <HeaderMenu
      className={classnames(className, { [styles.headerMenu]: isCollapsed })}
      isCompact={isCollapsed}
      isHomepage={isHomepage}
    >
      {/* https://leveltravel.atlassian.net/browse/LT-31772 */}
      {showFeedback && (
        <li data-testid={HEADER_CONTACT_US_TEST_ID}>
          <HeaderFeedback
            isCompact={isCollapsed}
            isHomepage={isHomepage}
            className={classnames({ [styles.homepageFeedback]: isHomepage })}
          />
        </li>
      )}
      {services && services.items && services.items.length !== 0 && (
        <HeaderMenuCategory
          className={classnames({
            [styles.fullServices]: !isCollapsed,
            [styles.homepageServices]: isHomepage,
            [styles.services]: !isHomepage,
          })}
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          data-testid={HEADER_SERVICES_TEST_ID}
          {...services}
        />
      )}
      {showWishlist && (
        <li data-testid={HEADER_MY_LIST_TEST_ID}>
          <HeaderWishlist
            isCompact={isCollapsed}
            setShowAuthFromWishlist={setShowAuthFromWishlist}
          />
        </li>
      )}
      {help && help.items && help.items.length !== 0 && (
        <HeaderMenuCategory
          className={classnames({
            [styles.homepageHelp]: isHomepage,
            [styles.help]: !isHomepage,
          })}
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          data-testid={HEADER_HELP_TEST_ID}
          {...help}
        />
      )}
      {showAuthorizationBlock && !isSSR && (
        <li data-testid={HEADER_LOGIN_TEST_ID}>
          <Suspense fallback={<div />}>
            <HeaderAuth
              isCompact={isCollapsed}
              showAuthFromWishlist={showAuthFromWishlist}
              data-testid={HEADER_LOGIN_TEST_ID}
            />
          </Suspense>
        </li>
      )}
    </HeaderMenu>
  );
};
export default DesktopMenu;
