/* eslint-disable no-underscore-dangle */
import { createSelector } from '@reduxjs/toolkit';
import { get, isEmpty, flattenDeep, isArray, some } from 'lodash';
import { getFromImmutableState } from '../../../../utils/immutableUtils';
import {
  transformFacilities,
  transformFacilitiesWithIcons,
} from '../../../../helpers/transformers';
import {
  HOTEL_FACTS_TYPES,
  HOTEL_FACTS_VALUES,
} from '../../constants/hotelFacts';
import { getOffersForCurrentDate } from './searcher';
import { getSearchOfferPrice } from './searchOffer';
import { getRouletteHotelTitle } from '../../../../utils/stringUtils';

/**
 * Возвращает иммутабельный отель:
 * @param {object} state reducer
 * @returns immutable hotel
 */
const getHotel = (state) => state.get('hotel');

export const getHotelData = getFromImmutableState(getHotel);

/**
 * Возвращает id отеля
 * @param {object} state reducer
 * @returns hotel id(number|stirng)
 */
export const getHotelId = (state): number | string => getHotel(state).get('id');

/**
 * Возвращает имя отеля
 * @param {object} state reducer
 * @returns hotel name(stirng)
 */
export const getHotelName = (state) => getHotel(state).get('name');

/**
 * Возвращает ссылку на отель
 * @param {object} state reducer
 * @returns hotel public_url(stirng)
 */
export const getHotelLink = (state) => getHotel(state).get('public_url');

/**
 * @param {object} state reducer
 * @returns наличие мест в отеле
 */
export const getHotelAccomodationAvailability = (state) =>
  getHotel(state).get('accommodation_availability');

/**
 * Возвращает количество звезд отеля
 * @param {object} state reducer
 * @returns количество звезд отеля
 */
export const getHotelStars = (state) => getHotel(state).get('stars');

/**
 * Возвращает id местоположения отеля
 * @param {object} state reducer
 * @returns id местоположения отеля
 */
export const getHotelPlaceId = (state) => getHotel(state).get('place_id');

/**
 * Возвращает местоположение отеля
 * @param {object} state reducer
 * @returns местоположение отеля
 */
export const getHotelPlace = (state) =>
  getHotel(state).getIn(['place', 'name_ru']);

/**
 * Возвращает название страны, в котором находится отель
 * @param {object} state reducer
 * @returns название страны, в котором находится отель
 */
export const getHotelCountryName = (state) =>
  getHotel(state).getIn(['country', 'name_ru']);

/**
 * Возвращает цену отеля
 * @param {object} state reducer
 * @returns цену отеля
 */
export const getInitialPrice = (state) => getHotel(state).get('price');

/**
 * Возвращает расположение отеля
 * @param {function} getHotelCountryName selector
 * @param {function} getHotelPlace selector
 * @returns расположение отеля
 */
export const getHotelLocation = createSelector(
  [getHotelCountryName, getHotelPlace],
  (country, place) => `${place}, ${country}`,
);

/**
 * Возвращает цены для предложений в текущем отеле
 * @param {function} getOffersForCurrentDate selector
 * @param {function} getSearchOfferPrice selector
 * @returns цены для предложений в текущем отеле
 */
export const getHotelPrices = createSelector(
  [getOffersForCurrentDate, getSearchOfferPrice],
  (items, offerPrice) => {
    if (isEmpty(items)) {
      return offerPrice || null;
    }
    // TODO: form min prices for dates on server;
    const allPrices = flattenDeep(
      // TODO: типизировать item
      items.map((item) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.values(item.offers).map((roomOffers: any) =>
          roomOffers.map((offer) => offer.price),
        ),
      ),
    );
    return allPrices;
  },
);

/**
 * Возвращает минимальную цену из всех предложений в отеле
 * @param {object} state reducer
 * @returns минимальную цену из всех предложений в отеле
 */
export const getHotelMinPrice = createSelector([getHotelPrices], (allPrices) =>
  isArray(allPrices) ? Math.min(...allPrices) : allPrices,
);

/**
 * Возвращает информацию об отеле
 * @param {object} state reducer
 * @returns информацию об отеле
 */
export const getHotelInfo = (state) => getHotel(state).get('hotel_info');

/**
 * Возвращает короткую информацию об отеле
 * @param {object} state reducer
 * @returns короткую информацию об отеле
 */
export const getHotelShortInfo = (state) => getHotel(state).get('short_info');

/**
 * Возвращает широту координатов отеля
 * @param {object} state reducer
 * @returns широту координатов отеля
 */
const getHotelLat = (state) => getHotel(state).get('lat');

/**
 * Возвращает долготу координатов отеля
 * @param {object} state reducer
 * @returns долготу координатов отеля
 */
const getHotelLong = (state) => getHotel(state).get('long');

/**
 * Возвращает координаты отеля
 * @param {function} getHotelLat selector
 * @param {function} getHotelLong selector
 * @returns координаты отеля
 */
export const getHotelCoordinates = createSelector(
  [getHotelLat, getHotelLong],
  (lat, long) => {
    if (!Number(lat) && !Number(long)) {
      return null;
    }

    return [long, lat];
  },
);

/**
 * Возвращает главную картинку
 * @param {object} state reducer
 * @returns главную картинку
 */
const getHotelMainImage = getFromImmutableState((state) =>
  getHotel(state).get('main_image'),
);

/**
 * Возвращает ссылку на главную картинку
 * @param {object} state reducer
 * @returns ссылку на главную картинку
 */
export const getHotelMainImageHref = createSelector(
  getHotelMainImage,
  (image) => get(image, ['file', 'x100x115', 'url']),
);

/**
 * Возвращает иммутабельные факты об отеле
 * @param {object} state reducer
 * @returns иммутабельные факты об отеле
 */
const _getFacts = (state) => getHotel(state).get('facts');

export type HotelFactsType = Record<
  string,
  string | number | boolean | object | undefined
>;

/**
 * Возвращает факты об отеле
 * @param {object} state reducer
 * @returns факты об отеле
 */
export const getHotelFacts = createSelector(_getFacts, (facts) => {
  if (!facts) {
    return {};
  }

  const factsObject: HotelFactsType = facts.toJS();
  const { line, ski_in, ski_out } = factsObject;

  if (line) {
    factsObject.beach_line = {
      line,
      distance: factsObject.beach_distance,
    };
    factsObject.line = undefined;
  }

  let skiInSkiOut = 'NONE';
  if (ski_in && ski_out) {
    skiInSkiOut = HOTEL_FACTS_VALUES.SKI_IN_SKI_OUT;
  } else if (ski_in) {
    skiInSkiOut = HOTEL_FACTS_VALUES.SKI_IN;
  } else if (ski_out) {
    skiInSkiOut = HOTEL_FACTS_VALUES.SKI_OUT;
  }
  factsObject[HOTEL_FACTS_TYPES.SKI_IN_SKI_OUT] = skiInSkiOut;

  return factsObject;
});

/**
 * Возвращает есть ли факты об отеле
 * @param {object} state reducer
 * @returns есть ли факты об отеле
 */
export const hasFacts = createSelector(getHotelFacts, (facts) =>
  some(facts, (val) => val && val !== 'NONE'),
);

/**
 * Возвращает удобства отеля
 * @param {object} state reducer
 * @returns удобства отеля
 */
const getFacilities = (facilities, setRandomBest?) =>
  createSelector(facilities, (items) =>
    transformFacilities(items, setRandomBest),
  );

/**
 * Возвращает удобства отеля с иконками
 * @param {object} state reducer
 * @returns удобства отеля с иконками
 */
const getFacilitiesWithIcons = (facilities, setRandomBest) =>
  createSelector(facilities, (items) =>
    transformFacilitiesWithIcons(items, setRandomBest),
  );

// TODO: убрать обертку getFacilities, когда будет норм бэк
/**
 * Возвращает удобства отеля связанные с территорией
 * @param {object} state reducer
 * @returns удобства отеля связанные с территорией
 */
export const getHotelFacilitiesTerritory = getFacilities(
  getFromImmutableState((state) => getHotel(state).get('territory')),
);

/**
 * Возвращает удобства отеля связанные с детями
 * @param {object} state reducer
 * @returns удобства отеля связанные с детями
 */
export const getHotelFacilitiesKids = getFacilities(
  getFromImmutableState((state) => getHotel(state).get('kids_facilities')),
);

/**
 * Возвращает удобства отеля связанные с аттракционами
 * @param {object} state reducer
 * @returns удобства отеля связанные с аттракционами
 */
export const getHotelFacilitiesAttractions = getFacilities(
  getFromImmutableState((state) => getHotel(state).get('attractions')),
);

/**
 * Возвращает удобства отеля связанные с номерами
 * @param {object} state reducer
 * @returns удобства отеля связанные с номерами
 */
export const getHotelFacilitiesRoom = getFacilitiesWithIcons(
  getFromImmutableState((state) => getHotel(state).get('room_traits')),
  false,
);
// не бесполезен ли room_facilities в hotel,
// в котором то же самое, что в room_traits, но нет имен иконок?
// TODO: унифицировать объект данных и убрать маппер
export const getHotelFacilitiesRoomForSearcher = createSelector(
  getHotelFacilitiesRoom,
  (items) =>
    items &&
    items.map((item) => ({
      ...item,
      name: item.text,
    })),
);

/**
 * Возвращает рулетку от отеля Fortuna*
 * @param {object} state reducer
 * @returns рулетку от отеля Fortuna*
 */
export const getHotelRoulette = (state) => getHotel(state).get('roulette');

/**
 * Возвращает заголовок в шапке отеля
 * @param {object} state reducer
 * @returns заголовок в шапке отеля
 */
export const getHotelTitle = createSelector(
  [getHotelName, getHotelStars, getHotelRoulette],
  (name, stars, isRoutlette) =>
    isRoutlette ? getRouletteHotelTitle(name, stars) : name,
);

/**
 * Возвращает иммутабельные лейблы отеля
 * @param {object} state reducer
 * @returns иммутабельные лейблы отеля
 */
export const _getHotelLabels = (state) => getHotel(state).get('labels');

/**
 * Возвращает лейблы отеля с фолбэком на пустой массив
 * @param {object} state reducer
 * @returns лейблы отеля
 */
export const getHotelLabels = getFromImmutableState(_getHotelLabels) || [];

/**
 * Возвращает прилинкованные отели
 * @param {object} state reducer
 * @returns прилинкованные отели
 */
const _getLinkedHotels = (state) => getHotel(state).get('linked_hotels');
export const getLinkedHotels = getFromImmutableState(_getLinkedHotels);

/**
 * Возвращает иммутабельные метаполитики отеля
 * @param {object} state reducer
 * @returns иммутабельные метаполитики отеля
 */
const _getHotelMetapolicies = (state) => getHotel(state).get('metapolicies');

/**
 * Возвращает метаполитики отеля
 * @param {object} state reducer
 * @returns метаполитики отеля
 */
export const getHotelMetapolicies = getFromImmutableState(
  _getHotelMetapolicies,
);

/**
 * Возвращает рейтинг отеля
 * @param {object} state reducer
 * @returns рейтинг отеля
 */
export const getHotelRating = (state) => getHotel(state).get('rating');
